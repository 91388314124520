exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-enjoy-jsx": () => import("./../../../src/pages/enjoy.jsx" /* webpackChunkName: "component---src-pages-enjoy-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-mixology-jsx": () => import("./../../../src/pages/mixology.jsx" /* webpackChunkName: "component---src-pages-mixology-jsx" */),
  "component---src-pages-products-jsx": () => import("./../../../src/pages/products.jsx" /* webpackChunkName: "component---src-pages-products-jsx" */),
  "component---src-pages-story-jsx": () => import("./../../../src/pages/story.jsx" /* webpackChunkName: "component---src-pages-story-jsx" */),
  "component---src-templates-product-template-jsx": () => import("./../../../src/templates/product-template.jsx" /* webpackChunkName: "component---src-templates-product-template-jsx" */)
}

